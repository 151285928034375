import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import Mainlogo from "../../Assets/mainlogo.png";
import { FaFacebookF, FaXTwitter, FaInstagram, FaLinkedinIn, FaPinterestP } from "react-icons/fa6";

export default function Footer(){
    return(
        <React.Fragment>
            <section className="footercontactform">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-6">
                            <div className="footerformcontn">
                                <h2>Leave your worries at the door and enjoy a healthier, more precise smile</h2>
                                <p>Ready to take the next step towards your perfect smile? Reach out to us today our team is here to answer your questions and schedule your personalized consultation.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="footerform">
                                <h2>Request Appointment</h2>
                                <form action="" method="post">
                                    <input type="text" className="form-control" placeholder="Full Name" />
                                    <input type="text" className="form-control" placeholder="Phone Number" />
                                    <input type="text" className="form-control" placeholder="Email Address" />
                                    <button type="submit">Submit</button>
                                </form>
                            </div>
                        </div>  
                    </div>
                </div>
            </section>
            <footer className="mainfooter">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-4">
                            <div className="footerlogo">
                                <Link to="/"><img src={Mainlogo} alt=""/></Link>
                            </div>
                            <div className="footercontnet">
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.</p>
                            </div>
                            <div className="footermenu">
                                <div className="footermenuleft">
                                    <h2>Company</h2>
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="#">About Us</Link></li>
                                        <li><Link to="#">Services</Link></li>
                                        <li><Link to="#">Contact Us</Link></li>
                                    </ul>
                                </div>
                                <div className="footermenuleft">
                                    <h2>Treatment</h2>
                                    <ul>
                                        <li><Link to="#">Dental Care</Link></li>
                                        <li><Link to="#">Oral Surgery</Link></li>
                                        <li><Link to="#">Oral Hygienist</Link></li>
                                        <li><Link to="#">Teeth Cleaning</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="footermaps">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861507.761719741!2d68.12664541249995!3d19.063576100000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c14ff90b878d%3A0x881a76abb24c2dd3!2sPanikar&#39;s%20Dentistry!5e0!3m2!1sen!2sin!4v1726509453200!5m2!1sen!2sin" width="100%" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Google map"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <section className="footterbotm">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="copyrighttext">
                                <p>© 2024 Copyright Panikar’s dentistry. All Rights Reserved.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="footersocialmedia">
                                <ul>
                                    <li><Link to="#"><FaFacebookF/></Link></li>
                                    <li><Link to="#"><FaXTwitter/></Link></li>
                                    <li><Link to="#"><FaInstagram/></Link></li>
                                    <li><Link to="#"><FaLinkedinIn/></Link></li>
                                    <li><Link to="#"><FaPinterestP/></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}