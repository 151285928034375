import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import "./header.css";
import { FaBars } from "react-icons/fa6";
import Mainlogo from "../../Assets/mainlogo.png"

export default function Header(){
    useEffect(() => {
        const header = document.querySelector('.mainheaderarea');
        const sticky = header.offsetTop;
        const handleScroll = () => {
            if (window.scrollY > sticky) {
                header.classList.add('sticky');
            } else {
                header.classList.remove('sticky');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return(
        <React.Fragment>
            <header className="mainheaderarea">
                <div className="container">
                    <div className="headerarea">
                        <div className="headerlogo">
                            <Link to="/"><img src={Mainlogo} alt="Main Logo"/></Link>
                        </div>
                        <div className="headermenu">
                            <ul>
                                <li><Link to="#">Home</Link></li>
                                <li><Link to="#">Treatments</Link></li>
                                <li><Link to="#">Smile Gallery</Link></li>
                                <li><Link to="#">Contact Us</Link></li>
                                <li className="booknowbtns"><Link to="#">Book Now</Link></li>
                                <li className="menutoggle"><FaBars/></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}