import React from "react";
import "./home.css";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";

export default function Home(){
    return(
        <React.Fragment>
            <Header/>
            <section className="bannerarea">
                <div className="container"></div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}